<script>
import VxAppBar from "@/components/vx/VxAppBar";
import VxAppBarSearch from "@/components/vx/VxAppBarSearch";
import EmployeeAction from "@/components/employees/EmployeeAction.js";

export default {
  name: "BouncebackAppBar",
  components: {
    VxAppBar,
    VxAppBarSearch,
    EmployeeAction,
  },
  props: {
    filter: {
      type: String,
      default: undefined,
    },
    parsedFilter: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    handleClick() {
      this.$router.push({ name: "bounceback.setup" });
    },
  },
};
</script>

<template>
  <VxAppBar
    :title="$t('bounceback.app.bouncebackAppBar.title')"
    mobile-search
    :filter="filter"
  >
    <template #search="{ back }">
      <VxAppBarSearch
        :title="$t('bounceback.app.bouncebackList.searchTitle')"
        :min-chars="3"
        :filter="filter"
        :parsed-filter="parsedFilter"
        @back="back"
      />
    </template>

    <template #rightMenuItems>
      <EmployeeAction
        v-slot="{ handleAction, isDisabled, listeners }"
        check-manager
      >
        <v-list-item
          ripple
          data-testid="menuBouncebackSettingsLink"
          v-on="listeners"
          @click="handleAction(handleClick)"
        >
          <v-list-item-title
            :class="{ 'text--disabled': isDisabled }"
            v-text="$t('bounceback.app.bouncebackList.bouncebackSettings')"
          />
        </v-list-item>
      </EmployeeAction>
    </template>
  </VxAppBar>
</template>
